import gjgConfig from './gjgConfig';
import i18n from './i18n';

pc.script.createLoadingScreen(function(app)
{
    gjgConfig.initialized;
    let wrapper: HTMLElement, splash: HTMLElement, background: HTMLImageElement, logo: HTMLImageElement,
        bgContainer: HTMLElement, barBG: HTMLImageElement, container: HTMLElement, bar: HTMLImageElement,
        tutorialImage1: HTMLImageElement, gameTitle: HTMLImageElement, tutorialText: HTMLElement,
        arcadeLogo: HTMLImageElement;
    let tutorialImageData = pc.platform.desktop ? {w: 890, h: 308} : {w: 469, h: 316};

    let setProgress = function(value: number)
    {
        let width = parseInt(bar.style.width, 10);
            
        value = pc.math.clamp(value, 0, 1);
        let clipSize = Math.floor(width - (width * value));
        
        let clipValue = 'inset(0px ' + clipSize.toString() + 'px 0px 0px)';

        bar.style.clipPath = clipValue;
        (<any>bar.style).webkitClipPath = clipValue;
    };
     
    let resizeFromRatio = function(element: HTMLElement, width: number, height: number, 
        ratioW: number, ratioH: number)
    {
        let w = ratioW < 1 ? width * ratioW : width;
        let h = ratioH < 1 ? height * ratioH : height;

        element.style.width = w.toString() + 'px';
        element.style.height = h.toString() + 'px';
    }

    var getWindowResizeData = function(maxW: number, maxH: number)
    {
        let ratio = maxW/maxH;
        var iW = window.innerWidth;
        var iH = window.innerHeight;
        var ratioW = iW/maxW;
        var w = maxW*ratioW;
        var h = maxH*ratioW;
        if(h > iH)
        {
            h = iH;
            w = ratio * iH;
            ratioW = w/maxW;
        }

        w = w > maxW ? maxW: w;
        h = h > maxH ? maxH: h;
    
        return { 
            width: w, height: h,
            innerWidth: iW, innerHeight: iH,
            wRatio: ratioW 
        };
    }

    var showSplash = function () {
        let desktop = pc.platform.desktop;
        // splash wrapper
        wrapper = document.createElement('div');
        wrapper.id = 'application-splash-wrapper';
        document.body.appendChild(wrapper);
        var resizeData = getWindowResizeData(1280, 720);
        // splash
        splash = document.createElement('div');
        splash.id = 'application-splash';
        wrapper.appendChild(splash);
        splash.style.display = 'block';

        background = document.createElement('img');
        background.id = 'application-background';
        splash.appendChild(background);
        background.onload = function () {
            background.style.display = 'block';
            background.style.width = '100%';
            background.style.height = '100%';
        };

        logo = document.createElement('img');
        logo.id = 'application-logo';
        splash.appendChild(logo);
        logo.onload = function () {
            logo.style.display = 'block';
            logo.style.position = 'absolute';
            logo.style.bottom = '1.5%';
            logo.style.left = '26%';
        };
        
        arcadeLogo = document.createElement('img');
        arcadeLogo.id = 'arcade-logo';
        splash.appendChild(arcadeLogo);
        arcadeLogo.onload = function () {
            arcadeLogo.style.display = 'block';
            arcadeLogo.style.position = 'absolute';
            arcadeLogo.style.bottom = '4.5%';
            arcadeLogo.style.right = '29%';
        };

        bgContainer = document.createElement('div');
        bgContainer.id = 'progress-bar-container-bg';
        bgContainer.style.width = '44.7%';
        bgContainer.style.bottom = '21.4%';
        bgContainer.style.height = '2%';
        splash.appendChild(bgContainer);

        barBG = document.createElement('img');
        barBG.id = 'progress-bar-bg';
        barBG.onload = function(){
            barBG.style.display = 'block';
            barBG.style.bottom = '0px';
            //resizeFromRatio(barBG, );

        };
        bgContainer.appendChild(barBG);
        
        container = document.createElement('div');
        container.id = 'progress-bar-container';
        container.style.position = 'relative';
        container.style.height = '100%';
        container.style.width = '100%';
        container.style.bottom = '-183%';
        container.style.left = '-4%';
        bgContainer.appendChild(container);
        
        bar = document.createElement('img');
        bar.id = 'progress-bar';
        bar.onload = function(){
            bar.style.display = 'block';
            bar.style.bottom = '0px';
            bar.style.width = '620px';
            bar.style.height = '65px';
            bar.style.position = 'absolute';
            bar.style.clipPath = 'inset(0px 620px 0px 0px)';
            resizeFromRatio(bar, 620, 65, resizeData.wRatio, resizeData.wRatio);
        };
        container.appendChild(bar); 

        tutorialImage1 = document.createElement('img');
        tutorialImage1.id = 'tutorial-image-1';
        splash.appendChild(tutorialImage1); 
        tutorialImage1.onload = function(){
            tutorialImage1.style.display = 'block';
            tutorialImage1.style.position = 'absolute';
            tutorialImage1.style.width = tutorialImageData.w.toString();
            tutorialImage1.style.height = tutorialImageData.h.toString();
        };

        if(desktop)
        {
            tutorialImage1.style.left = '15.25%';
            tutorialImage1.style.bottom = '29%';
        }
        else
        {
            tutorialImage1.style.left = '31.75%';
            tutorialImage1.style.bottom = '28.6%';
        }


        gameTitle = document.createElement('img');
        gameTitle.id = 'game-title';
        splash.appendChild(gameTitle); 
        gameTitle.onload = function(){
            gameTitle.style.display = 'block';
            gameTitle.style.position = 'relative';
            gameTitle.style.bottom = '98.5%';
            gameTitle.style.marginLeft = 'auto';
            gameTitle.style.marginRight = 'auto';
        };


        var tutorialKey = 'loading_desktop'
        var textSize = '17px';
        var textLeft = '-1px';
        var textBottom = '26.2%';

        if(!desktop)
        {
            tutorialKey = 'loading_mobile';
            textSize = resizeData.width < resizeData.height ? '4.3vh': '2.4vw';
            textBottom = '22.4%'
        }


        tutorialText = document.createElement('P');
        tutorialText.id = 'tutorial-text';
        tutorialText.innerHTML = i18n.getString(tutorialKey);
        tutorialText.style.display = 'block';
        tutorialText.style.position = 'absolute';
        tutorialText.style.left = textLeft;
        tutorialText.style.bottom = textBottom;
        tutorialText.style.width = '100%';
        tutorialText.style.textAlign = 'center';
        tutorialText.style.color ='white';
        tutorialText.style.fontSize = textSize;
        tutorialText.style.textShadow = '1px 11px 9px black';
        tutorialText.style.wordSpacing = '1px';
        splash.appendChild(tutorialText);

    
        var tutorialImageFile = desktop ? 'onboarding_desktop.png': 'onboarding_mobile.png';

        var rootDir = 'nonGame/';
        background.src          = rootDir + 'background.jpg';
        logo.src                = rootDir + 'blue_jade_logo.png';
        arcadeLogo.src                = rootDir + 'GJG_Arcade_Logo.png';
        barBG.src               = rootDir + 'loader-bg.png';
        bar.src                 = rootDir + 'loader-bar.png';        
        tutorialImage1.src      = rootDir + tutorialImageFile;
        gameTitle.src           = rootDir + '_headers.png';        
    };

    var hideSplash = function () {
        //return;
        var splash = document.getElementById('application-splash-wrapper');
        if(!splash)
            return;

        splash.parentElement.removeChild(splash);
        window.removeEventListener('resize'           , splashResize);
        window.removeEventListener('orientationchange', splashResize);
    };
    
    var createCss = function () {
        var css = [
            'body {',
            '    background-color: #000000;',
            '}',

            '#application-splash-wrapper {',
            '    position: absolute;',
            '    top: 0;',
            '    left: 0;',
            '    height: 100%;',
            '    width: 100%;',
            '    background-color: #000000;',
            '}',

            '#application-splash {',
            '    position: relative;',
            '    top: 0;',
            '    left: 0;',
            '    width: 100%;',
            '    height: 100%;',
            '}',

            '#application-logo {',
            '    width: auto;',
            '    height: auto;',
            '}',
            
            '#progress-bar-container-bg {',
            '    position: relative;',
            '    margin: 0 auto 0 auto;',
            //'    bottom: 10.2%;',
            //'    height: 19px;',
            //'    width: 44.7%;',
            '    background-color: transparent;',
            '}',
            
            '#progress-bar-container {',
            '    position: relative;',
            '    height: 100%;',
            '    width: 100%;',
            '    background-color: transparent;',
            '}',      
            '#progress-bar-bg {',
            '    position: absolute;',
            '    width: 0%;',
            //'    height: 1%;',
            '    width: 100%;',
            '    height: 100%;',
            '}',
            
            '@media (max-width: 100%) {',
            '    #application-splash {',
            '        width: 100%px;',
            '        height: 100%px;',
            '    }',
            '}'
        ].join("\n");

        var style = <any>document.createElement('style');
        style.type = 'text/css';
        if (style.styleSheet) 
        {
          style.styleSheet.cssText = css;
        }
        else 
        {
          style.appendChild(document.createTextNode(css));
        }
        document.head.appendChild(style);
    };

        
    var splashResize = function()
    {
        var resizeData = getWindowResizeData(1280, 720);
        var ratioW = resizeData.wRatio;
        var iW = resizeData.innerWidth;
        var iH = resizeData.innerHeight;
        var w = resizeData.width;
        var h = resizeData.height;
        var ratioH = ratioW;

        wrapper.style.width =  w + 'px';
        wrapper.style.height =  h + 'px';
        wrapper.style.left = ((iW - w)/2).toString() + 'px';
        wrapper.style.top = ((iH - h)/2).toString() + 'px';

        resizeFromRatio(logo, 274, 126, ratioW, ratioH);
        resizeFromRatio(arcadeLogo, 218, 66, ratioW, ratioH);
        resizeFromRatio(tutorialImage1, tutorialImageData.w, tutorialImageData.h, ratioW, ratioH);
        resizeFromRatio(gameTitle, 632, 237, ratioW, ratioH);
        resizeFromRatio(bar, 620, 65, ratioW, ratioH);
        
    };

    /// Methods calling
    createCss();
    showSplash();
    splashResize();
    
    /// Window Events
    window.addEventListener('resize', splashResize); 
    window.addEventListener('orientationchange', splashResize);
        
    // Playcanvas Events
    app.on('preload:start', function(){ });
    app.on('preload:end', function () {
        app.off('preload:progress');
    });
    app.on('preload:progress', setProgress);
    app.once('start', hideSplash);
});