import accounting = require("accounting");
const getSymbolFromCurrency = <(code: string)=> string>require('currency-symbol-map');
import languages from './../Utils/LocSheets';

type currencyType = 'GBP'| 'EUR'| 'USD'| 'DKK'| 'SEK'| 'NOK'| 'CHF'| 'CZK'| 'HRK'| 'RON'| 'RUB'| 
'UAH'| 'CNY'| 'JPY'| 'KRW'| 'THB'| 'VND'| 'CAD'| 'PLN';

const isInPlaycanvas: boolean = /playcanvas/.test(document.location.hostname);

class i18n
{
    currencySymbol: string = '€';
    currencyGrouper: string = '.';
    currencyDecimal: string = ',';
    currencyFormat: string = '%s%v';
    i18nStrings: { [id:string]: string };

    getString(stringId: string)
    {
        let str = this.i18nStrings[stringId];
		if(str)
			return str;

		console.error("No string found with id of " + stringId);
		return "";
    }

    loadLoc(langCode: string)
    {
        this.setLoc(languages[langCode]);
    }

    setLoc(json: JSON)
    {
        let jsonObject = JSON.parse(JSON.stringify(json));
		this.i18nStrings = jsonObject;
	}


    hasString(stringId: string)
    {
        return !!this.i18nStrings[stringId];
	}

    setCurrency(currency: string)
    {
        let currencySymbol = getSymbolFromCurrency(currency);
        currencySymbol = !!currencySymbol ? currencySymbol : this.currencySymbol;

        let currencyGrouper = this.getCurrencyGroupingCharacter(currency);
        currencyGrouper = !!currencyGrouper ? currencyGrouper : this.currencyGrouper;

        let currencyDecimalChar = this.getDecimalSeparator(currency);
        currencyDecimalChar = !!currencyDecimalChar ? currencyDecimalChar: this.currencyDecimal;

        let currencyFormat = this.getCurrencyFormat(currency);
        currencyFormat = !!currencyFormat ? currencyFormat : this.currencyFormat;

        this.initAccounting(currencySymbol, currencyGrouper, currencyDecimalChar,
            currencyFormat);
    }

    getCurrencyGroupingCharacter(currency: string)
    {
        const characters: Record<string, currencyType[]> = {
            ',': ['GBP', 'USD', 'CHF', 'CNY', 'JPY', 'KRW', 'THB', 'CAD'],
            '.': ['EUR', 'DKK', 'SEK', 'NOK', 'CZK', 'HRK', 'RON','RUB', 'VND'],
            ' ': ['UAH', 'PLN']
        }

        for(let char in characters)
        {
            let currencies = characters[char];
            let index = currencies.indexOf(<currencyType>currency);
            if(index != -1) 
                return char;
        }
        return undefined;
    }

    getDecimalSeparator(currency: string)
    {
        const characters: Record<string, currencyType[]> = {
            '.': ['GBP', 'USD', 'CHF', 'CNY', 'THB', 'CAD'],
            ',': ['EUR', 'DKK', 'SEK', 'NOK', 'CZK', 'HRK', 'RON', 'RUB', 'UAH', 'PLN'],
            '_': ['JPY', 'KRW', 'VND'] /** underscore means currency has no decimals */
        }

        for(let char in characters)
        {
            let currencies = characters[char];
            let index = currencies.indexOf(<currencyType>currency);
            if(index != -1) 
                return char;
        }
        return undefined;
    }

    getCurrencyFormat(currency: string)
    {
        const formats: Record<string, currencyType[]> = {
            '%s%v': ['GBP', 'EUR', 'USD', 'CHF', 'UAH', 'CNY', 'JPY', 'KRW', 'THB', 'CAD'],
            '%v %s': ['DKK', 'SEK', 'NOK', 'CZK', 'HRK', 'RON', 'PLN'],
            '%v%s': ['RUB', 'VND'],
        }
        for(let char in formats)
        {
            let currencies = formats[char];
            let index = currencies.indexOf(<currencyType>currency);
            if(index != -1) 
                return char;
        }
        return undefined;
    }

    initAccounting(symbol: string, grouper: string, decimal: string, format: string)
    {
        let precision = decimal === '_'? 0 : 2;

        let currencySettings = accounting.settings.currency;
        currencySettings = {
            symbol: symbol,
            thousand: grouper,
            decimal: decimal,
            format: format,
            precision: precision
        };

        let numberSettings = accounting.settings.number;
        numberSettings = {
            decimal: decimal,
            thousand: grouper,
            precision: 0
        }

        accounting.settings.currency = currencySettings;
        accounting.settings.number = numberSettings;
    }

    formatMoney(quantity: number)
    {
        return accounting.formatMoney(quantity);
    }

    formatNumber(quantity: number)
    {
        return accounting.formatNumber(quantity);
    }

    formatFixed(quantity: number, precision: number)
    {
        return accounting.formatNumber(quantity, precision);
    }
}


(<any>window).gjgi18n = (<any>window).gjgi18n ? (<any>window).gjgi18n : new i18n();
const _i18n: i18n = (<any>window).gjgi18n;

export default _i18n;