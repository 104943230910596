const cn = require('../Loc/cn.json');
const cs = require('../Loc/cs.json');
const da = require('../Loc/da.json');
const de = require('../Loc/de.json');
const en = require('../Loc/en.json');
const es = require('../Loc/es.json');
const fi = require('../Loc/fi.json');
const fr = require('../Loc/fr.json');
const hr = require('../Loc/hr.json');
const it = require('../Loc/it.json');
const ja = require('../Loc/ja.json');
const lv = require('../Loc/lv.json');
const ko = require('../Loc/ko.json');
const no = require('../Loc/no.json');
const pl = require('../Loc/pl.json');
const pt = require('../Loc/pt.json');
const ro = require('../Loc/ro.json');
const ru = require('../Loc/ru.json');
const sv = require('../Loc/sv.json');
const th = require('../Loc/th.json');
const tw = require('../Loc/tw.json');
const ukr = require('../Loc/ukr.json');
const vi = require('../Loc/vi.json');
const zh = require('../Loc/zh.json');

const languages: {[lang: string]:JSON} = {
  cn: cn,
  cs: cs,
  da: da,
  de: de,
  en: en,
  es: es,
  fi: fi,
  fr: fr,
  hr: hr,
  it: it,
  ja: ja,
  lv: lv,
  ko: ko,
  no: no,
  pl: pl,
  pt: pt,
  ro: ro,
  ru: ru,
  sv: sv,
  th: th,
  tw: tw,
  ukr: ukr,
  vi: vi,
  zh: zh
};

export default languages;