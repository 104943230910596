


export abstract class QueryParameters
{
    static getValue(parameter: string)
    {
        if(!document)
            return '';
        let queryString = document.location.search || document.location.hash;
        let result = '';

        if(queryString && parameter)
        {
            let queryVars = /\?/.test(queryString) ? queryString.split('?')[1]: "";
            let pairs = queryVars.split('&');

            pairs.forEach(pair =>
            {
                let eqInd = pair.indexOf('=');

                if(pair.substring(0, eqInd) == parameter)
                {
                    result = pair.substring((eqInd + 1));
                }
            });
        }
        return result;
    }
}