import { QueryParameters as QParams } from "./QueryParameters";
import i18n from "./i18n";
import languages from "./LocSheets";

type LPCL = any;

interface Handler
{
    method: any,
    context: any;
}

export class ConfigProps 
{
    betAmounts: [{
        amounts: [];
        currencies: [];
    }];
    initialBetIndices: [{
        initialIndex: 0;
        currencies: [];
    }];
    conversionsToCredits: [{
        conversionToCredits: 100.0;
        currencies: [];
    }];
    defaultBetAmounts: number[] = [1, 2, 5, 10, 20, 50, 100, 200, 500];
    defaultInitialBetIndex: number = 0;
    historyLimit: number = 50;
    availableLanguages: string[] = ["cs", "da", "de", "en", "es", "fi", "fr", "hr", "it", "ja",
        "ko", "lv", "no", "pl", "pt", "ro", "ru", "sv", "th", "tw", "ukr", "vi", "zh"];
    alternateLogos: string[] = ["cn", "ko", "ja", "th", "tw", "vi", "zh"];
    enableCloseTab: boolean;
    enableRoundId: boolean;
    language: string = 'en';
    locale: string = 'en-US';
    currency: string = 'EUR';
    jurisdiction: string = '';
    platform: string = '';
    server: string = '';
    freeServer: string = '';
    prodMode: boolean = false;
    maxBet: number = 1000000000;
    minBet: number = 0;
    mode: 'real' | 'fun' | '' = 'real';
    pauseHandler: Handler;
    resumeHandler: Handler;
    wrapByCharacterLang: boolean;
    sessionId: string;
    forceIfCashDecreasedCheckBox: boolean;
    isPC: any;
    skipTutorial: boolean;
};

class GJGConfigAPI
{
    private _props: ConfigProps = new ConfigProps();
    get props(): ConfigProps { return this._props; }
    lpclData: any;
    lpcl: any;
    promise: Promise<unknown>;
    initialized: boolean;

    constructor()
    {        
        let props = this._props;

        let onLoadCfg = (data: any) =>
        {
            /*
            this.lpclData = data;
            let lpcl = this.lpcl;
            lpcl.enableFreeSpins();
            lpcl.postInitLoad();

            lpcl.addPauseHandler(props.pauseHandler.method, props.pauseHandler.context);
            lpcl.addResumeHandler(props.resumeHandler.method, props.resumeHandler.context);
            */
            if ((props.mode === 'fun' && props.sessionId === '') ||
                (props.mode === '' && props.sessionId === ''))
            {
                props.sessionId = decodeURIComponent(QParams.getValue('sessionId'));
                let localSessionId = localStorage.getItem('sessionId')
                if (props.sessionId === '' && localSessionId == undefined)
                {
                    //props.sessionId = `${uuidv4()}_${this.currency}`;
                    localStorage.setItem('sessionId', props.sessionId);
                }
                else if (localSessionId)
                {
                    props.sessionId = localSessionId;
                }
                console.log(props.sessionId);
            }

            // check bet amounts, initial bet value, and the currency conversion for the current
            // currency type 
            // this._checkBetAmounts();
            // this._checkInitialBetIndex();
            // this._checkCurrencyConversion();

            let skipTutorial = decodeURIComponent(QParams.getValue('skipTutorial'));
            props.skipTutorial = skipTutorial !== '' ? skipTutorial === 'true' : props.skipTutorial;

            let maxBet = decodeURIComponent(QParams.getValue('maxbet'));
            props.maxBet = maxBet !== "" ? parseInt(maxBet) : props.maxBet;

            let minBet = decodeURIComponent(QParams.getValue('minbet'));
            props.minBet = minBet !== "" ? parseInt(minBet) : props.minBet;

            let userLocale = decodeURIComponent(QParams.getValue('userLocale'));
            props.locale = userLocale != '' ? userLocale.replace('_', '-') : props.locale;
            let language = props.locale.substring(0, 2);
            props.language = language === '' ? props.language : language.toLowerCase();
            props.language = props.language !== 'zh' ? props.language :
                props.locale.substr(props.locale.length - 2, props.locale.length).toLowerCase();

            // Check the jurisdiction in case it needs to force the appearance of a cash decreased checkbox in the autospin screen
            if (props.jurisdiction == "GB" || props.jurisdiction == "UK")
            {
                props.forceIfCashDecreasedCheckBox = true;
            }
            // Check if it will need the special requirements for the asian market
            if (props.jurisdiction === "CN" || props.jurisdiction === "JP")
            {
                props.enableCloseTab = !props.isPC;
                props.enableRoundId = true;
            }

            //// Check if the texts should wrap by character or by word
            if (props.language == "cn" ||
                props.language == "th" ||  
                props.language == "ja" ||
                props.language == "zh" ||
                props.language == "tw")
            {
                props.wrapByCharacterLang = true;
            }
            else
            {
                props.wrapByCharacterLang = false;
            }
            
            let currency = decodeURIComponent(QParams.getValue('currency'));
            props.currency = currency != '' ? currency : props.currency;


            //self.lobbyActive = (self.lobbyURL != null && self.lobbyURL != "");
            // Clear the url parameters now that the parameters are stored an no longer needed
            //if(self.prodMode)
            //{
            //    var beginning = document.location.href.split("?")[0];
            //    history.replaceState(null, null, beginning);
            //}
            //self.promise = null;

            i18n.setCurrency(props.currency);
            i18n.loadLoc(props.language);
        };
        onLoadCfg(null);
    /*
        let promise = new Promise((resolve, reject) =>
        {
            console.log(props);
            let lpcl: any; //= new LPCL();
            this.lpcl = lpcl;

            lpcl.loadPlatformConfig(props.platform, props.server, props.freeServer).
                then(onLoadCfg).catch(reject);
            resolve();
        });

        this.promise = promise;
        return promise;
    */
        
        this.initialized = true;
    }


    setBetAmounts()
    {

    }
}


(<any>window).gjgCfg = (<any>window).gjgCfg ? (<any>window).gjgCfg : new GJGConfigAPI();
const gjgCfg: GJGConfigAPI = (<any>window).gjgCfg;

export default gjgCfg;
